import React from 'react';
import styled from 'styled-components';
import amplifyicon from '../images/logos/aws-amplify.png';
import cloudformationicon from '../images/logos/aws-cloudFormation.png';
import controltowericon from '../images/logos/aws-control-tower.png';
import reactjsicon from '../images/logos/reactJS.png';

const TechDiv = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 100px;
`
const IconDiv = styled.div`
    flex: 0 1 100px;
    margin: 5px;
`

const IconImg = styled.img`
    display: flex;
`


const Tech = () => {
    return (
        <TechDiv>
            <IconDiv>
                <IconImg src={amplifyicon}></IconImg>
                <h5>AWS Amplify</h5>
            </IconDiv>
            <IconDiv>
                <IconImg src={cloudformationicon}></IconImg>
                <h5>AWS CloudFormation</h5>
            </IconDiv>
            <IconDiv>
                <IconImg src={controltowericon}></IconImg>
                <h5>AWS Control Tower</h5>
            </IconDiv>

        </TechDiv>
    )
}


export default Tech;