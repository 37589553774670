import background from '../images/background/hong-kong-1990268_1920.jpg';
import GlassCard from '../components/glasscard';
import styled  from 'styled-components';

//Styled-Components

//page
const StyledPage = styled.div`
    width: 100%;
        position: relative;
    min-height: 100vh;
    background: url(${background}) center center / cover no-repeat;
  `

// Application Fucntion
function App() {
  return (
    <StyledPage>
        <GlassCard />
    </StyledPage>
  );
}

export default App;
