
import React from 'react';
import profile from '../images/headshots/headshot.jpg';
import styled from 'styled-components';
import Tech from './tech';

// -Components

const HeadShot = styled.img`
    display: block;
    width: 100%;
    height: auto;
    width: 100%;
    max-width: 200px;
    border-radius: 50%;
    margin: 0 auto 10px; 
`

const DivContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 1100px;
    padding: 40px 2%;
    margin: 0 auto;
  `
const Grid = styled.div` 
    display: grid;
    grid-template-columns: minmax(260px, 35%) 1fr;
    border-radius: 20px;
    min-height: 90vh;
`

const Section1 = styled.section`
    background: rgba(0, 0, 0, 0.8);
    background: rgba(250, 250, 250, .8);
    padding: 30px;
    margin: 0 1px;
    border-radius: 20px 10px 10px 20px;
    backdrop-filter: blur(10px);
`

const Section2 = styled.section`
    background: rgba(0, 0, 0, 0.8);
    background: rgba(250, 250, 250, 0.7);
    padding: 30px;
    margin: 0 1px;
    border-radius: 10px 20px 20px 10px;
    text-align: center;
    backdrop-filter: blur(10px);
`

const Logo = styled.span`
    display: block;
    font-family: 'Encode Sans', sans-serif;
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 20px;  
`

const LogoSpan = styled.span`
    font-size: 30px;
    color: #aaa;
    color: #808080;
`
// HTML properties
const h3 = styled.h3`
    font-weight: 500;
`
const p = styled.p`
    font-weight: 300;
    padding-bottom: 1em; 
`

const h1 = styled.h1`
    font-size: 34px;
    margin-bottom: 0.5em;  
`

// App Component
const GlassCard = () => {
    return (
        <DivContainer>
            <Grid>
                <Section1>
                        <Logo>G<LogoSpan>abicus</LogoSpan>W<LogoSpan>orks</LogoSpan></Logo>
                        <h3>Professinal Service</h3>
                        <p>Navigate businesses through the vast services of Cloud Computing at all levels.</p>
                    <br />
                    <h3>Enterprise Solutions</h3>
                        <ul>
                            <li>Cloud and Hybrid architecture: Cloud-based gives you power and security along with room to grow with your business.</li>
                            <li>Migration: Using cloud services to simplify migration.</li>
                            <li>Security: End to end solutions for both security and all forms of compliances. </li>
                            <li>Administration:</li>
                        </ul>
                        <br />
                    <h3>Five Pillars of Cloud Architecture</h3>
                        <ul>
                            <li>Operational Excellence</li>
                            <li>Security</li>
                            <li>Reliability</li>
                            <li>Performance Efficiency</li>
                            <li>Cost Optimization</li>
                        </ul>
                </Section1>
                <Section2>
                    <h1>Richard Comito</h1>
                    <HeadShot src={profile}></HeadShot>
                    <h2>Cloud Solution Architect</h2>
                    <h5>Office: 310-876-2955<br />Email: <a href="Rich@GabicusWorks.com">Rich@GabicusWorks.com</a></h5>
                    <Tech></Tech>
                </Section2>
            </Grid>
        </DivContainer>
    );
}

export default GlassCard;